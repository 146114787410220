@tailwind base;
@tailwind components;
@tailwind  utilities;

@tailwind base;
@tailwind components;
@tailwind  utilities;

.tablelisting {
    @apply focus:bg-gray-50 hover:bg-gray-100 focus:text-blue-500 hover:text-blue-500
}
.righticon {
    @apply text-[1.8em] ml-8 hover:text-white ;
  
}

.rightnav{
    @apply hover:bg-blue-500  focus:bg-blue-500 text-blue-500 active:bg-blue-500 hover:text-white;
    border-radius: 10px ;
}
.menubtn {
   z-index: 100; 
}

.sidedrawer {
    position: fixed;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    background: #f8f8f8;
    width: 50%;
    height: 100%;
    max-width: 400px;
    top: 0;
    right: 0;
    z-index: 105;
    transform: translateX(100%); 
    transition: transform 0.3s ease-out;
  }

  .sidedrawer.open {
    transform: translateX(0);
  }
  .dropdown{
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    background: #f8f8f8;
    z-index: 100;
  }
  
.backdrop {
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
    top: 0;
  }

  
  